import React from 'react'
export default () => {
  return (
    <svg
      width="320px"
      height="240px"
      viewBox="0 0 320 240"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className="Quote__Bg-Image"
    >
      <g
        id="modules"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Quote__Bg-Image-Inner"
          transform="translate(-1240.000000, 0.000000)"
          fill="#111212"
        >
          <path
            d="M1560,240 L1437.2093,240 C1424.84721,204.444267 1418.60465,167.703893 1418.60465,129.230769 C1418.60465,91.8516622 1429.68519,60.7408622 1452.09302,36.9230769 C1474.00802,12.1480267 1506.62538,-1.75442651e-13 1548.83721,0 L1548.83721,59.0769231 C1517.23789,57.7777778 1501.00724,77.3331378 1500.46512,118.153846 L1500.46512,129.230769 L1560,129.230769 L1560,240 Z M1381.39535,240 L1258.60465,240 C1246.24256,204.444267 1240,167.703893 1240,129.230769 C1240,91.8516622 1251.08054,60.7408622 1273.48837,36.9230769 C1295.40337,12.1480267 1328.02073,-1.75442651e-13 1370.23256,0 L1370.23256,59.0769231 C1338.63324,57.7777778 1322.40259,77.3331378 1321.86047,118.153846 L1321.86047,129.230769 L1381.39535,129.230769 L1381.39535,240 Z"
            id="quotes"
          ></path>
        </g>
      </g>
    </svg>
  )
}
