// Libraries
import React from 'react'

// Components
import { Helmet } from 'react-helmet'

import QuoteSlider from '../quote/quoteSlider'
import VerticalTabsSlider from '../verticalTab'

const SliderComponent = (props: any) => {
  if (props.data) {
    const sliderProps = props.data
    const useCase = sliderProps.useCase ? sliderProps.useCase : 'Quote'

    if (sliderProps.panels && sliderProps.panels.length > 0) {
      let component
      switch (useCase) {
        case 'Vertical Tabs':
          component = <VerticalTabsSlider data={props.data} />
          break
        default:
          component = <QuoteSlider data={props.data} />
      }
      return (
        <>
          <Helmet>
            <link
              rel="stylesheet"
              type="text/css"
              href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css"
            />
          </Helmet>
          {component}
        </>
      )
    } else {
      return null
    }
  } else {
    return null
  }
}

export default SliderComponent
