import styled from 'styled-components'

export const QuoteOuter = styled.div`
  ${({ quoteTheme, theme }) => {
    let quoteFill, dottedGridColor, bgColor, quoteBGImageStyles
    if (quoteTheme === 'Light') {
      quoteFill = '#F4F4F5'
      dottedGridColor = '#000000'
      bgColor = '#FFFFFF'
    } else if (quoteTheme === 'Grey') {
      quoteFill = '#FFFFFF'
      dottedGridColor = '#000000'
      bgColor = theme.webMktPalette.background.gray
    } else if (quoteTheme === 'Dark') {
      quoteFill = 'rgba(255,255,255,.10)'
      dottedGridColor = 'rgba(255,255,255,.50)'
      bgColor = theme.webMktPalette.background.dark
    }

    quoteBGImageStyles = `
        top: 96px;
        ${theme.breakpoints.down('sm')} {
          top: 64px;
        }
        ${theme.breakpoints.down('xs')} {
          top: 338px;
        }
      `

    return `
      background: ${bgColor};
      ${theme.breakpoints.down('xs')} {
        padding-left: 16px;
        padding-right: 16px;
      }
      .Quote__Image-Container:before {
        background-image: radial-gradient(${dottedGridColor} 1px, transparent 0);
      }
      #Quote__Bg-Image-Inner {
        fill: ${quoteFill};
      }
      .Quote__Text,.Quote__Text p {
        color: ${
          quoteTheme === 'Light' || quoteTheme === 'Grey'
            ? theme.webMktPalette.text.primaryBlack
            : theme.webMktPalette.text.primaryWhite
        }
      }
      .Quote__Bg-Image {
        ${quoteBGImageStyles}
      }
  `
  }}
`

export const QuoteInner = styled.div`
  position: relative;
  padding: 96px 0 160px 0;
  max-width: 996px;
  margin: 0 auto;
  ${(p) => p.theme.breakpoints.down('md')} {
    max-width: 796px;
  }
  ${(p) => p.theme.breakpoints.down('sm')} {
    max-width: 736px;
  }
  ${(p) => p.theme.breakpoints.down('xs')} {
    padding: 64px 0 104px 0;
    max-width: 329px;
  }
  .Quote__Bg-Image {
    width: 282px;
    height: 211px;
    position: absolute;
    right: 0;
    z-index: 0;
    ${(p) => p.theme.breakpoints.down('md')} {
      width: 222px;
      height: 166px;
      right: -84px;
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
      width: 173px;
      height: 129px;
      right: 0;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      width: 156px;
      height: 116px;
    }
  }

  .Quote__Inner {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: flex-start;
    justify-content: space-between;
    ${(p) => p.theme.breakpoints.down('xs')} {
      flex-direction: column;
    }
    .Quote__Image-Container {
      position: relative;
      flex-basis: 282px;
      width: 282px;
      height: 282px;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      ${(p) => p.theme.breakpoints.down('md')} {
        height: 221px;
        width: 221px;
        flex-basis: 221px;
      }
      ${(p) => p.theme.breakpoints.down('sm')} {
        height: 172px;
        width: 172px;
        flex-basis: 172px;
        margin-left: 42px;
      }
      ${(p) => p.theme.breakpoints.down('xs')} {
        height: 242px;
        width: 242px;
        flex-basis: 242px;
        margin-left: 0;
      }
      &:before {
        content: '';
        position: absolute;
        width: 205px;
        height: 205px;
        z-index: 0;
        right: -64px;
        bottom: -64px;
        background-size: 24px 24px;
        ${(p) => p.theme.breakpoints.down('md')} {
          height: 159px;
          width: 159px;
          right: -48px;
          bottom: -48px;
        }
        ${(p) => p.theme.breakpoints.down('sm')} {
          right: -40px;
          bottom: -40px;
        }
        ${(p) => p.theme.breakpoints.down('xs')} {
          height: 178px;
          width: 181px;
          right: -64px;
          bottom: 32px;
        }
      }
    }
    .Quote__Image {
      position: relative;
      z-index: 1;
      height: 100%;
      width: auto;
      min-width: 100%;
      object-fit: cover;
    }
    .Quote__Text {
      flex-basis: 588px;
      flex-shrink: 1;
      margin-left: 126px;
      margin-right: auto;
      ${(p) => p.theme.breakpoints.down('md')} {
        width: 468px;
        flex-basis: 468px;
        margin-left: 106px;
      }
      ${(p) => p.theme.breakpoints.down('sm')} {
        width: 454px;
        flex-basis: 454px;
        margin-left: 23px;
        margin-right: 0;
      }
      ${(p) => p.theme.breakpoints.down('xs')} {
        margin-left: 0;
        margin-top: 24px;
        width: 100%;
        flex-basis: 100%;
      }
      .Quote__Author {
        margin-top: 24px;
      }
      .Quote__Title {
        margin-top: 3px;
        ${(p) => p.theme.breakpoints.down('xs')} {
          margin-top: 8px;
        }
      }
      .Quote__CTA {
        margin-top: 21px;
        ${(p) => p.theme.breakpoints.down('xs')} {
          margin-top: 16px;
        }
      }
    }
  }

  // QUOTE SLIDER
  &.Quote__Slider.Quote__Slider {
    .Quote__Bg-Image {
      top: 184px;
      ${(p) => p.theme.breakpoints.down('sm')} {
        top: 144px;
      }
      ${(p) => p.theme.breakpoints.down('xs')} {
        top: 338px;
      }
    }

    .Quote__Text {
      max-width: 819px;
      margin-left: 0;
    }

    .Quote__Image {
      position: absolute !important;
      top: 0;
      left: 0;
    }

    .slick-slide {
      overflow: hidden;
      opacity: 0;
      transition: opacity 240ms cubic-bezier(0.4, 0.14, 0.3, 1) 75ms;

      &.slick-current {
        opacity: 1;
        transition: opacity 240ms cubic-bezier(0.4, 0.14, 0.3, 1) 245ms;
      }
    }

    .Quote__Text {
      flex-basis: unset;
      width: auto;
    }

    .Quote__Image {
      opacity: 0;
      transition: opacity 240ms cubic-bezier(0.4, 0.14, 0.3, 1);
      &.Quote__Image--Active {
        transition: opacity 240ms cubic-bezier(0.4, 0.14, 0.3, 1);
        opacity: 1;
      }
    }

    .Quote__Slider-Item {
      margin-left: 126px;
      max-width: 588px;

      ${(p) => p.theme.breakpoints.down('md')} {
        max-width: 468px;
      }
      ${(p) => p.theme.breakpoints.down('sm')} {
        max-width: 454px;
      }
    }

    .Quote__Slider-Nav-Container {
      margin-left: 126px;
    }

    ${(p) => p.theme.breakpoints.down('sm')} {
      .Quote__Slider-Nav-Container,
      .Quote__Slider-Item {
        margin-left: 70px;
      }
    }

    ${(p) => p.theme.breakpoints.down('xs')} {
      .Quote__Slider-Nav-Container,
      .Quote__Slider-Item {
        margin-left: 0px;
      }

      .Quote__Text {
        max-width: 328px;
      }

      .Quote__Slider-Item {
        max-width: 328px;
      }
    }
  }

  .Quote__Slider-Nav-Container {
    margin: 0 0 32px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    ${(p) => p.theme.breakpoints.down('xs')} {
      margin: 12px 0 24px 0;
      z-index: 2;
      position: relative;
    }
    .Quote__Slider-Prev-Button,
    .Quote__Slider-Next-Button {
      padding: 0;
      .MuiIconButton-label {
        img {
          width: 56px;
          height: 56px;
          ${(p) => p.theme.breakpoints.down('md')} {
            width: 48px;
            height: 48px;
          }
        }
      }
    }
    .Quote__Slider-Slide-Position {
      margin: 0 16px;
      font-size: 20px;
      font-weight: normal;
      letter-spacing: -0.5px;
      line-height: 32px;
    }
  }
`

export const BlogQuoteWHeadshot = styled.div`
  && {
    background-color: ${(p) => p.theme.webMktPalette.background.white};
    position: relative;
    margin: 80px 0 124px 0;
    border-left: 0;
    padding: 48px;
    ${(p) => p.theme.breakpoints.down('sm')} {
      margin: 80px 0 92px 0;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      padding: 48px 24px;
      margin: 80px 0 124px 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: -30px;
      left: 48px;
      width: 74px;
      height: 56px;
      background-image: url('https://images.ctfassets.net/5965pury2lcm/6D8NmS3Zf7JZLkVIzD0UjL/97aa5dce32ab883531b4697ff3cf531e/heist-blog-blockquote-quotes.svg');
    }

    p {
      color: #273139;
    }

    p:last-child,
    p:last-child span {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      margin-top: 32px;
    }

    p:first-child {
      font-size: 24px;
      line-height: 36px;
      font-weight: 400;
      letter-spacing: -0.5px;
      margin-bottom: 32px;
      ${(p) => p.theme.breakpoints.down('md')} {
        font-size: 20px;
        line-height: 32px;
      }
      ${(p) => p.theme.breakpoints.down('sm')} {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0px;
      }
      ${(p) => p.theme.breakpoints.down('xs')} {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0px;
      }
    }
  }
  .BlockQuote__Byline {
    max-width: 306px;
    margin: 0 0 0 184px !important;
    ${(p) => p.theme.breakpoints.down('md')} {
      max-width: 188px;
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
      max-width: 300px;
      margin: 0 0 0 152px !important;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      max-width: 100%;
      margin: 0 !important;
    }
  }
  .BlockQuote__Headshot-Img {
    width: 160px;
    height: 160px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 24px 0 0;
    position: absolute;

    ${(p) => p.theme.breakpoints.down('sm')} {
      width: 128px;
      height: 128px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      width: 96px;
      height: 96px;
      margin: 0;
      bottom: -64px;
    }
    .BlockQuote__Headshot-Img-File {
      width: 100%;
      height: auto;
      min-height: 100%;
      object-fit: cover;
    }
  }
`
