import styled from 'styled-components'
import Arrow from '../../../../static/steam-img/arrows/16x16_Arrow_Up.svg'

export const VerticalTabContainer = styled.div`
  ${(p) => {
    let paddingStyles = ''
    let mobilePaddingBottom = ''
    if (p.topPadding === 'Large') paddingStyles += 'padding-top: 96px;'
    else if (p.topPadding === 'Small') paddingStyles += 'padding-top: 64px;'
    if (p.bottomPadding === 'Large') {
      paddingStyles += 'padding-bottom: 160px;'
      mobilePaddingBottom = 'padding-bottom: 96px;'
    } else if (p.bottomPadding === 'Small') {
      paddingStyles += 'padding-bottom: 128px;'
      mobilePaddingBottom = 'padding-bottom: 64px;'
    }

    paddingStyles = paddingStyles ? paddingStyles : 'padding-bottom: 160px;'

    mobilePaddingBottom = mobilePaddingBottom
      ? mobilePaddingBottom
      : 'padding-bottom: 64px;'

    return `
      ${paddingStyles}
      ${p.theme.breakpoints.down('sm')} {
        ${mobilePaddingBottom}
      }
    `
  }}
  background: ${(p) => p.theme.webMktPalette.background.gray};
  position: relative;
  z-index: 1;

  .VerticalTabs__Grid-Container {
    margin: 0 -12px;
    position: relative;
    ${(p) => p.theme.breakpoints.down('sm')} {
      &:before {
        content: '';
        background-image: radial-gradient(black 1px, transparent 0);
        height: 48px;
        width: 370px;
        position: absolute;
        top: -10px;
        right: 12px;
        background-size: 24px 24px;
      }
    }

    ${(p) => p.theme.breakpoints.down('xs')} {
      &:before {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .VerticalTabs__Content-Container,
  .VerticalTabs__Menu {
    padding-top: 0;
    padding-bottom: 0;
  }

  .VerticalTabs__Menu {
    margin-top: 113px;
    ${(p) => p.theme.breakpoints.down('sm')} {
      margin-top: 64px;
    }

    > ul > li {
      padding: 16px 24px;
      cursor: pointer;
      margin-bottom: 9px;
      &:hover {
        background: #ecedee;
      }

      transition: background 240ms cubic-bezier(0.4, 0.14, 0.3, 1);

      &.VerticalTabs__Active--Desktop {
        background: #0067df;
        p {
          color: rgb(255, 255, 255);
        }
      }

      .VerticalTabs__Accordion-Item {
        display: none;
      }

      ${(p) => p.theme.breakpoints.down('sm')} {
        margin-bottom: 0;
        padding: 25px 37px 25px 16px;
        border-bottom: 1px solid #c0c2c5;

        &:first-of-type {
          border-top: 1px solid #c0c2c5;
        }

        &:hover {
          background: none;
        }

        .VerticalTabs__Label {
          font-size: 18px;
          font-weight: 600;
          letter-spacing: -1px;
          line-height: 20px;
          position: relative;

          &:after {
            content: '';
            background-image: url(${Arrow});
            width: 21px;
            height: 15px;
            background-size: contain;
            top: 0px;
            right: -21px;
            position: absolute;
            background-size: contain;
            background-repeat: no-repeat;
            transform: rotate(180deg);
          }
        }

        &.VerticalTabs__Active--Mobile {
          .VerticalTabs__Label:after {
            transform: none;
          }

          .VerticalTabs__Label {
            padding-bottom: 24px;
            position: relative;
            &:before {
              content: '';
              position: absolute;
              bottom: 0;
              width: 172px;
              left: -16px;
              border: 1px solid #c0c2c5;
            }
          }

          .VerticalTabs__Accordion-Item {
            margin-top: 32px;
            display: block;
            max-width: 626px;
          }
        }

        &.VerticalTabs__Active--Desktop {
          background: none;
          p {
            color: #000;
          }
        }
      }
    }
  }

  .VerticalTabs__Content-Container {
    margin-top: 18px;
    height: 100%;
    position: relative;

    ${(p) => p.theme.breakpoints.down('sm')} {
      display: none;
    }

    .VerticalTabs__Content-Inner {
      position: relative;
      z-index: 3;
    }

    .VerticalTabs__Content-Outer {
      padding-bottom: 116px;
      position: relative;
      background: rgb(255, 255, 255);

      ${(p) => p.theme.breakpoints.down('md')} {
        padding-bottom: 100px;
      }
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-size: 24px 24px;
      background-image: radial-gradient(black 1px, transparent 0);
    }

    &:before {
      top: -30px;
      right: -102px;
      width: 385px;
      height: 123px;
      z-index: 2;
    }

    &:after {
      bottom: -72px;
      left: -64px;
      z-index: -1;
      width: 243px;
      height: 128px;
    }

    ${(p) => p.theme.breakpoints.down('md')} {
      margin-top: 48px;

      &:before {
        width: 304px;
        height: 123px;
        top: -60px;
        right: -82px;
      }

      &:after {
        width: 200px;
        height: 128px;
      }
    }
  }

  p {
    margin: 0;
  }

  .VerticalTabs__Item-Body {
    margin-top: 24px;
    ${(p) => p.theme.breakpoints.down('sm')} {
      margin-top: 32px;
    }

    > p {
      margin-bottom: 24px;
    }

    ul {
      list-style-type: initial;
    }

    li {
      margin-bottom: 8px;
      padding-left: 6px;
    }

    ul,
    ol {
      margin-bottom: 24px;
      padding-left: 24px;
    }
  }

  .VerticalTabs__Slider {
    position: relative;

    .VerticalTabs__Slider-Item > div {
      max-width: 486px;
      margin-left: 102px;
      margin-bottom: 24px;
      ${(p) => p.theme.breakpoints.down('md')} {
        margin-left: 82px;
        max-width: 468px;
      }
    }

    .slick-slide {
      opacity: 0;
      transition: opacity 240ms cubic-bezier(0.4, 0.14, 0.3, 1) 75ms;

      &.slick-current {
        opacity: 1;
        transition: opacity 240ms cubic-bezier(0.4, 0.14, 0.3, 1) 245ms;
      }
    }
  }

  //--- DECORATIONS ---//

  .VerticalTabs__Mac {
    position: absolute;
    object-fit: cover;
    width: 690px;
    height: 345px;
    right: 12px;
    top: 0px;
    z-index: 2;

    ${(p) => p.theme.breakpoints.down('md')} {
      width: 550px;
      height: 275px;
    }

    ${(p) => p.theme.breakpoints.down('sm')} {
      width: 360px;
      height: 180px;
      z-index: -1;
    }

    ${(p) => p.theme.breakpoints.down('xs')} {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .VerticalTabs__Robot {
    position: absolute;
    width: 282px;
    height: 315px;
    bottom: -64px;
    right: -102px;
    object-fit: contain;
    z-index: 5;
    ${(p) => p.theme.breakpoints.down('md')} {
      width: 222px;
      height: 248px;
      right: -82px;
    }
  }

  .VerticalTabs__Circle {
    width: 180px;
    height: 180px;
    background: rgb(236, 237, 238);
    position: absolute;
    bottom: -64px;
    left: 101px;
    border-radius: 50%;
    ${(p) => p.theme.breakpoints.down('md')} {
      width: 140px;
      height: 140px;
      left: 82px;
    }
  }

  //--- SLIDER ARROWS  ---//

  .VerticalTabs__Slider-Nav-Container {
    padding-top: 94px;
    margin: 0 0 24px 102px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    z-index: 2;
    ${(p) => p.theme.breakpoints.down('md')} {
      margin-left: 82px;
    }
    .VerticalTabs__Slider-Prev-Button,
    .VerticalTabs__Slider-Next-Button {
      padding: 0;
      .MuiIconButton-label {
        img {
          width: 56px;
          height: 56px;
          ${(p) => p.theme.breakpoints.down('md')} {
            width: 48px;
            height: 48px;
          }
        }
      }
    }
    .VerticalTabs__Slider-Slide-Position {
      margin: 0 16px;
      font-size: 20px;
      font-weight: normal;
      letter-spacing: -0.5px;
      line-height: 32px;
    }
  }
`
