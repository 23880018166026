import React from 'react'
import * as styles from './style'
import Button from '../button'
import ImageUtils from '../../../utils/img.utils'
import GlobalContainer from '../globalContainer'
import formatInlineCode from '../../../utils/shortTextFormatting'
import { internalUrl, internalLinkHandler } from '../../../utils/link.utils' 

const MainCTA = (props: any) => {
  if (props.data) {
    let title: string = '',
      subtitle: string = '',
      cta: { externalUrl: string; text: string; internalLink?: internalUrl}[] | undefined,
      disclaimer: string = ''

    if (props.data.title) title = props.data.title
    if (props.data.subtitle) subtitle = props.data.subtitle
    if (props.data.ctAs) cta = props.data.ctAs
    if (props.data.disclaimer) disclaimer = props.data.disclaimer

    let backgroundDesktopUrl = ImageUtils.getResizedImageUrl(
      'https://images.ctfassets.net/5965pury2lcm/7IFUtInC8kyPBw8YZGvbId/c0eb86d7d29130b8e08e8d9b8b83f8f5/MainCTA-LG-MD.png',
      ''
    )
    let backgroundMobileUrl = ImageUtils.getResizedImageUrl(
      'https://images.ctfassets.net/5965pury2lcm/4Jh9GmFfspu4LTyHp5fGEv/803f9c7cf5baeeb3299f21fcc12e7e3d/MainCTA-SM-XS.png',
      ''
    )
    return (
      <styles.MainCTA
        backgroundDesktop={backgroundDesktopUrl}
        backgroundMobile={backgroundMobileUrl}
        data-cy="MainCTA"
      >
        <GlobalContainer>
          <div className="MainCTA__Inner">
            <h2 className="MainCTA__Title">{formatInlineCode(title)}</h2>
            {subtitle && (
              <p className="MainCTA__Subtitle">{formatInlineCode(subtitle)}</p>
            )}
            <div className="MainCTA__Buttons">
              {cta &&
                cta[0] &&
                (cta[0].externalUrl || cta[0].internalLink) &&
                cta[0].text && (
                  <Button
                    icon={true}
                    href={
                      cta[0].internalLink
                        ? internalLinkHandler(cta[0].internalLink)
                        : cta[0].externalUrl
                    }
                    text={cta[0].text}
                    type="primary"
                    size="small"
                    theme="light"
                  />
                )}

              {cta &&
                cta[1] &&
                (cta[1].externalUrl || cta[1].internalLink) &&
                cta[1].text && (
                  <Button
                    icon={true}
                    href={
                      cta[1].internalLink
                        ? internalLinkHandler(cta[1].internalLink)
                        : cta[1].externalUrl
                    }
                    text={cta[1].text}
                    type="tertiary"
                    size="small"
                    theme="light"
                  />
                )}
            </div>
            {disclaimer && (
              <p className="MainCTA__Disclaimer">
                {formatInlineCode(disclaimer)}
              </p>
            )}
          </div>
        </GlobalContainer>
      </styles.MainCTA>
    )
  }

  return null
}

export default MainCTA
