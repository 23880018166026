import styled from 'styled-components'

export const SliderOuter = styled.div`
  ${({ theme, background }) => {
    let bgColor
    if (background === 'Light') {
      bgColor = theme.webMktPalette.background.white
    } else if (background === 'Grey') {
      bgColor = theme.webMktPalette.background.gray
    } else if (background === 'Dark') {
      bgColor = theme.webMktPalette.background.dark
    }
    return `
      background: ${bgColor};
    `
  }}
`

export const CarouselItem = styled.div`
  .Carousel__Item-Inner {
    padding: 0 0 160px 0;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: flex-start;
    ${(p) => p.theme.breakpoints.down('xs')} {
      flex-direction: column;
      max-width: 328px;
      margin: 0 auto;
      padding: 0 0 64px 0;
    }
    &:before {
      content: '';
      background-size: 320px 240px;
      width: 320px;
      height: 240px;
      background-repeat: no-repeat;
      background-position: right;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      ${(p) => p.theme.breakpoints.down('sm')} {
        background-size: 160px 120px;
        width: 160px;
        height: 120px;
        top: 100px;
      }
      ${(p) => p.theme.breakpoints.down('xs')} {
        top: 355px;
      }
    }
    .Carousel__Item-Image-Container {
      position: relative;
      margin-right: 126px;
      width: 100%;
      max-width: 486px;
      ${(p) => p.theme.breakpoints.down('xs')} {
        max-width: 328px;
        margin-right: 0;
        height: 328px;
      }
      &:before {
        content: '';
        bottom: -70px;
        position: absolute;
        width: 366px;
        height: 247px;
        z-index: 0;
        right: -82px;
        bottom: -82px;

        background-size: 24px 24px;
        ${(p) => p.theme.breakpoints.down('xs')} {
          display: none;
        }
      }
    }
    .Carousel__Item-Nav-Container {
      margin: 32px 0 48px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      ${(p) => p.theme.breakpoints.down('xs')} {
        margin: 24px 0 32px 0;
        z-index: 2;
        position: relative;
      }
      .Carousel__Item-Prev-Button,
      .Carousel__Item-Next-Button {
        width: 56px;
        height: 56px;
        .MuiIconButton-label {
          img {
            height: 12px;
            width: 24px;
          }
        }
      }
      .Carousel__Item-Slide-Position {
        margin: 0 16px;
        font-size: 20px;
        font-weight: normal;
        letter-spacing: -0.5px;
        line-height: 32px;
      }
    }
    .Carousel__Item-Image {
      margin-right: 112px;
      position: relative;
      z-index: 1;
      width: 100%;
      height: auto;
    }
    .Carousel__Item-Text {
      width: 100%;
      max-width: 486px;

      .Carousel__Item-Heading {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -1px;
        line-height: 36px;
        ${(p) => p.theme.breakpoints.down('xs')} {
          font-size: 20px;
          line-height: 28px;
          letter-spacing: 0;
          margin-top: 24px;
        }
      }
      .Carousel__Item-SubHeading {
        margin-top: 24px;
        font-size: 20px;
        font-weight: normal;
        letter-spacing: -0.5px;
        line-height: 32px;
        ${(p) => p.theme.breakpoints.down('xs')} {
          font-size: 18px;
          letter-spacing: 0px;
          line-height: 24px;
        }
      }
      .Carousel__Item-Byline {
        margin-top: 24px;
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 20px;
        font-weight: bold;
      }
    }
  }
`
