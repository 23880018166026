import React from 'react'
import { KeyStatsContainer } from './style'
import GlobalContainer from '../globalContainer'
import Typography from '../typography'
import formatInlineCode from '../../../utils/shortTextFormatting'

const KeyStats = (props: any) => {
  let homepageBg: boolean = false
  let isCustomerStory: boolean = false

  if (props.data) {
    let theme: string = 'Light',
      finePrint: string = '',
      stats: any,
      topPadding: string = 'None',
      bottomPadding: string = 'Large'

    if (props.isCustomerStory) {
      isCustomerStory = props.isCustomerStory
    }

    if (props.isHomePage) {
      homepageBg = props.isHomePage
    }

    if (props.data.keyStatsFinePrint && props.data.keyStatsFinePrint.finePrint)
      finePrint = props.data.keyStatsFinePrint.finePrint
    if (props.data.topPadding) topPadding = props.data.topPadding
    if (props.data.bottomPadding) bottomPadding = props.data.bottomPadding
    if (props.data.stats) {
      stats =
        props.data.stats.length > 3 && !isCustomerStory
          ? props.data.stats.slice(0, 3)
          : props.data.stats
    }
    if (props.data.theme && !isCustomerStory) theme = props.data.theme

    const textType = {
      upperText: 'header6',
      stat: isCustomerStory ? 'header3' : 'display',
      text: isCustomerStory ? 'bodyXS' : 'header6',
    }

    return (
      <KeyStatsContainer
        background={theme}
        topPadding={topPadding}
        bottomPadding={bottomPadding}
        homePageBg={homepageBg}
        data-cy={`KeyStats${isCustomerStory ? '-CustomerStory' : ''}`}
        isCustomerStory={isCustomerStory}
      >
        <GlobalContainer className="KeyStats__Outer-Container">
          <div className="KeyStats__Inner-Container">
            {stats &&
              stats.length &&
              stats.map((item: any, index: number) => (
                <div className="KeyStats__Item" key={index}>
                  {item.upperText && (
                    <Typography
                      className="KeyStats__UpperText"
                      type={textType.upperText}
                    >
                      {item.upperText}
                    </Typography>
                  )}
                  {item.number && (
                    <Typography
                      type={textType.stat}
                      className="KeyStats__Number"
                    >
                      {item.number}
                    </Typography>
                  )}
                  {item.text && (
                    <Typography className="KeyStats__Text" type={textType.text}>
                      {item.text}
                    </Typography>
                  )}
                </div>
              ))}
          </div>
          {finePrint && (
            <p className="KeyStats__FinePrint">{formatInlineCode(finePrint)}</p>
          )}
        </GlobalContainer>
      </KeyStatsContainer>
    )
  } else {
    return null
  }
}

export default KeyStats
