import styled from 'styled-components'

export const PlatformDiagramOuter = styled.div`
  ${({ theme }) => {
    return `
      padding: 96px 0;
      ${theme.breakpoints.down('xs')} {
        padding: 96px 0 64px 0;
      }
      .PlatformDiagram__Top-Outer {
        display: flex;
        justify-content: space-between;
        ${theme.breakpoints.down('xs')} {
          position: relative;
        }
        .PlatformDiagram__Top-Grid {
          width: 587px;
          height: 208px;
          background-image: radial-gradient(rgba(0,0,0,.7) 1px, transparent 0);
          background-size: 24px 24px;
          ${theme.breakpoints.down('xs')} {
            width: 155px;
            height: 208px;
            position: absolute;
            right: 0;
          }
        }
        .PlatformDiagram__Heading-Container {
          margin-top: 64px;
          max-width: 588px;
          margin-right: 24px;
          ${theme.breakpoints.down('xs')} {
            max-width: 100%;
          }
        }
        .PlatformDiagram__Heading {
          color: #000B23;
        }
        .PlatformDiagram__Heading-Orange-Line {
          display: block;
          height: 4px;
          width: 80px;
          background: #FF6901;
          margin-bottom: 28px;
        }
      }
      .PlatformDiagram__Navigation-Group {
        display: flex;
        justify-content: space-between;
        margin: 48px 0;
        ${theme.breakpoints.down('sm')} {
          flex-wrap: wrap;
          margin: 16px 0 48px 0; 
        }
        .PlatformDiagram__Nav-Item {
          flex-basis: 180px;
          ${theme.breakpoints.down('md')} {
            flex-basis: 140px;
          }
          ${theme.breakpoints.down('sm')} {
            flex-basis: 236px;
            margin: 16px 0;
          }
          ${theme.breakpoints.down('xs')} {
            flex-basis: 100%;
            margin: 12px 0;
          }
        }
      }
      .PlatformDiagram__Nav-Item {
        .PlatformDiagram__Nav-Item-Heading {
          font-weight: 800;
          margin-bottom: 16px;
          letter-spacing: 0;
          ${theme.breakpoints.up('md')} {
            font-size: 24px;
            line-height: 32px;
          }
        }
        .PlatformDiagram__Nav-Item-Description {
          margin-bottom: 8px;
          color: #273139;
        }
        .PlatformDiagram__Nav-Item-Link {
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #0067DF;
          display: block;
          margin: 8px 0;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .PlatformDiagram__Bottom-Heading {
        ${theme.breakpoints.up('md')} {
          letter-spacing: -1px;
        }
      }
      .PlatformDiagram__Bottom-Grid {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        ${theme.breakpoints.down('xs')} {
          flex-wrap: wrap;
          flex-direction: column;
        }
        .PlatformDiagram__Icon-Text {
          flex-basis: 234px;
          display: flex;
          align-items: center;
          ${theme.breakpoints.down('md')} {
            flex-basis: 192px;
          }
          ${theme.breakpoints.down('sm')} {
            flex-basis: 156px;
          }
          ${theme.breakpoints.down('xs')} {
            flex-basis: 100%;
            margin: 8px 0;
          }
        }
        .PlatformDiagram__Icon-Text-Image-Container {
          width: 64px;
          height: 64px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          ${theme.breakpoints.down('sm')} {
            width: 32px;
            height: 32px;
          }
          ${theme.breakpoints.down('xs')} {
            width: 64px;
            height: 64px;
          }
        }
        .PlatformDiagram__Icon-Text-Image {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
        .PlatformDiagram__Icon-Text-Copy {
          margin: 0 0 0 8px;
          ${theme.breakpoints.down('xs')} {
            margin: 0 0 0 20px;
          }
        }
      }
      .Menu-container-label--black {
        color: #000000;
      }
      .Menu-container-label--blue {
        color: #0067df;
      }
      .Menu-container-label--orange {
        color: #fa4616;
      }
      .Menu-container-label--red {
        color: #ed145b;
      }
      .Menu-container-label--purple {
        color: #933692;
      }
      .Menu-container-label--lightBlue {
        color: #38c6f4;
      }
      .Menu-container-label--green {
        color: #249b49;
      }
      .PlatformDiagram__Icon-Arrow {
        ${theme.breakpoints.up('lg')} {
          display: none;
        }
        ${theme.breakpoints.down('xs')} {
          display: none
        }
      }
  `
  }}
`
