import React from 'react'
import { Container } from './style'

const GlobalContainer = (props: any) => {
  return (
    <Container {...(props.className ? { className: props.className } : {})}>
      {props.children}
    </Container>
  )
}

export default GlobalContainer
