import React from 'react'
import { StyledGlobalContainer, TemplateOne, TemplateTwo } from './style'
import getMarkup from './parser'

/*
  JSONProperties -
    type => text, button, or check
    value => a string representing the value of the type selected, can contain html
    values => an array containing multiple instances of JSONProperties (only nests one layer)
    outerClassName => optional className for table cell (td/th)
    innerClassName => optional className for table cell child element (e.g. typography, button)
    textType => Header1, CaptionAlt, Label...etc
    buttonType => primary, secondary, tertiary, or text-link
    link => url
    columns => number of columns, all "rows" in json must have length of columns except for "divider/subDivider" objects
    templateType => TemplateOne, TemplateTwo
    toolTipText => tooltip for TemplateTwo topics

    First row in json provides table configurations e.g. columns, templateType
 */

const Tables = (props: any) => {
  if (props.data) {
    let templates = ['TemplateOne', 'TemplateTwo']

    let tableData =
      props.data.tableData && props.data.tableData.tableData
        ? props.data.tableData.tableData
        : ''

    let topPadding = props.data.topPadding ? props.data.topPadding : 'Small'
    let bottomPadding = props.data.bottomPadding
      ? props.data.bottomPadding
      : 'Large'

    let template: any, columns: any
    if (tableData.length && tableData[0] && tableData[0].length) {
      template = templates.includes(tableData[0][0].templateType)
        ? tableData[0][0].templateType
        : ''
      columns = tableData[0][0].columns
    }

    //TemplateOne requires at least 2 columns (for mobile layout)
    if (
      !columns ||
      isNaN(columns) ||
      !template ||
      (template === 'TemplateOne' && columns < 2)
    )
      return null

    const formDesktopTable = (data: any) => {
      let content = []
      for (let i = 1; i < data.length; i++) {
        if (!Array.isArray(data[i])) return null
        if (data[i].length === 1) {
          if (data[i][0].type === 'divider')
            content.push(
              <tr>
                <td className="divider"></td>
              </tr>
            )
          else if (data[i][0].type === 'subDivider')
            content.push(
              <tr>
                <td className="subDivider"></td>
              </tr>
            )
          continue
        }

        if (data[i].length !== columns) return null

        let current = []
        for (let j = 0; j < data[i].length; j++) {
          current.push(getMarkup(data[i][j], template, i))
        }
        content.push(<tr>{current}</tr>)
      }

      return <table className="Table__Desktop">{content}</table>
    }

    // Used for Automation Cloud and Studio Table => TemplateOne
    // columns >= 2
    const formMobileTable = (data: any) => {
      let allContent = []
      for (let i = 1; i < data.length; i++) {
        if (!Array.isArray(data[i])) return null
        let current = []
        let j = 1

        if (data[i].length === 1) {
          if (data[i][0].type === 'divider')
            current.push(
              <tr>
                {new Array(columns - 1).fill(0).map(() => (
                  <td className="divider" colSpan={2}></td>
                ))}
              </tr>
            )
          else if (data[i][0].type === 'subDivider')
            current.push(
              <tr>
                {new Array(columns - 1).fill(0).map(() => (
                  <td className="subDivider" colSpan={2}></td>
                ))}
              </tr>
            )
          allContent.push(current)
          continue
        }

        if (data[i].length !== columns) return null
        if (i === 1) {
          // First row
          while (j < columns) {
            const slide = <div className="Table__Slide">&lt; slide &gt;</div>
            current.push(
              getMarkup(data[i][j], template, i, slide, { colSpan: 2 })
            )
            j++
          }
        } else if (
          data[i][0] &&
          data[i][0].outerClassName === 'Table__Subheading'
        ) {
          //subheading
          while (j < columns) {
            current.push(
              getMarkup(data[i][0], template, i, null, { colSpan: 2 })
            )
            j++
          }
        } else {
          while (j < columns) {
            current.push(
              getMarkup(data[i][0], template, i, null, { colSpan: 1 }),
              getMarkup(data[i][j], template, i, null, { colSpan: 1 })
            )
            j++
          }
        }

        allContent.push(<tr>{current}</tr>)
      }
      return (
        <div className="Table__Mobile">
          <table>{allContent}</table>
        </div>
      )
    }

    const desktopTable = formDesktopTable(tableData)
    if (!desktopTable) return null

    if (template === 'TemplateOne') {
      const mobileTable = formMobileTable(tableData)
      if (!mobileTable) return null
      return (
        <TemplateOne data-cy={`Table-${template}`}>
          <StyledGlobalContainer
            topPadding={topPadding}
            bottomPadding={bottomPadding}
          >
            {desktopTable}
            {mobileTable}
          </StyledGlobalContainer>
        </TemplateOne>
      )
    } else if (template === 'TemplateTwo')
      return (
        <TemplateTwo data-cy={`Table-${template}`}>
          <StyledGlobalContainer
            topPadding={topPadding}
            bottomPadding={bottomPadding}
          >
            {desktopTable}
          </StyledGlobalContainer>
        </TemplateTwo>
      )
    else return null
  } else return null
}

export default Tables
