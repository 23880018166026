import styled from 'styled-components'

export const DividerContainer = styled.div`
  ${({ componentTheme, theme, bottomPadding, topPadding }) => {
    const lightThemeBg = theme.webMktPalette.background.white
    const grayThemeBg = theme.webMktPalette.background.gray
    const darkThemeBg = theme.webMktPalette.background.dark
    let themeStyles, bottomPaddingStyles, topPaddingStyles

    if (componentTheme === 'Light') {
      const background = theme.webMktPalette.background.white
      themeStyles = `
        background: ${background};
        &:before {
          background: ${theme.webMktPalette.background.gray};
        }
        .Divider__Text {
          background: ${background};
        }
      `
    }

    if (componentTheme === 'Grey') {
      const background = theme.webMktPalette.background.gray
      themeStyles = `
        background: ${background};
        &:before {
          background: ${theme.webMktPalette.background.white};
        }
        .Divider__Text {
          background: ${background};
        }
      `
    }

    if (componentTheme === 'Dark') {
      themeStyles = `
        // Dark Styles
        background: ${theme.webMktPalette.background.white};
        &:before {
          background: ${theme.webMktPalette.background.dark};
        }
        .Divider__Text {
          background: ${theme.webMktPalette.background.white};
        }
      `
    }

    if (bottomPadding === 'Large') {
      bottomPaddingStyles = '96px'
    }
    if (bottomPadding === 'Small') {
      bottomPaddingStyles = '64px'
    }
    if (bottomPadding === 'None') {
      bottomPaddingStyles = '0'
    }

    if (topPadding === 'Large') {
      topPaddingStyles = '96px'
    }
    if (topPadding === 'Small') {
      topPaddingStyles = '64px'
    }
    if (topPadding === 'None') {
      topPaddingStyles = '0'
    }

    return `
      ${themeStyles}
      padding-top: ${topPaddingStyles};
      padding-bottom: ${bottomPaddingStyles};
      position: relative;
      text-align: center;
      ${theme.breakpoints.down('xs')} {
        padding-bottom: 64px;
      }

      &:before {
        content: '';
        width: 100%;
        height: 320px;
        position: absolute;
        top: 0;
        left: 0;
        ${theme.breakpoints.down('md')} {
          height: 216px;
        }
        ${theme.breakpoints.down('xs')} {
          display: none;
        }
      }

      .Divider__Heading {
        margin: 0 auto;
      }
      .Divider__Heading,
      .Divider__Subheading p {
        color: ${theme.webMktPalette.text.primaryBlack};
      }

      .Divider__Subheading {
        margin: 24px auto 32px auto;
        ${theme.breakpoints.down('xs')} {
          margin: 24px auto;
        }
        ul,ol {
          margin: 24px auto 0 auto;
          width: fit-content;
        }
      }


      .Divider__Image-Container {
        position: relative;
        z-index: 0;
        .Divider__Image {
          height: 540px;
          width: auto;
          min-width: 100%;
          object-fit: cover;
          ${theme.breakpoints.down('md')} {
            height: 432px;
          }
          ${theme.breakpoints.down('xs')} {
            height: 320px;
          }
          @media(max-width: 360px) {
            height: 240px;
          }
        }
      }

      .Divider__Text {
        width: calc(100% - 64px);
        max-width: 996px;
        margin: 0 auto;
        padding: 64px 102px 0 102px;
        margin: -108px auto 0 auto;
        position: relative;
        z-index: 1;
        box-sizing: border-box;
        ${theme.breakpoints.down('sm')} {
          padding: 48px 94px 0 94px;
        }
        ${theme.breakpoints.down('xs')} {
          margin: -80px auto 0 auto;
          width: calc(100% - 32px);
          padding: 32px 16px 0 16px;
        }

        .Divider__Text-Inner {
          max-width: 792px;
          width: 100%;
          margin: 0 auto;
          > *:last-child {
            margin-bottom: 0;
          }
          ${theme.breakpoints.down('xs')} {
            min-height: 72px;
          }
        }
      }

      .Divider__Inner {
        overflow: hidden;
        max-width: 1404px;
        width: 100%;
        margin: 0 auto;
        ${theme.breakpoints.down('md')} {
          max-width: 1123px;
        }
        ${theme.breakpoints.down('sm')} {
          max-width: 768px;
        }
      }

      .MuiContainer-root {
        ${theme.breakpoints.down('xs')} {
          padding: 0;
        }
      }
    `
  }}
`
