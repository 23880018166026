import styled from 'styled-components'

export const ContentColumnsContainer = styled.div`
  ${({
    moduleTheme,
    theme,
    topPadding,
    bottomPadding,
    textAlignment,
    orangeDivider,
  }) => {
    let themeStyles,
      topPaddingStyles,
      bottomPaddingStyles,
      orangeLineStyles,
      richTextStyles
    if (moduleTheme === 'Dark') {
      themeStyles = `
        // Dark Styles
        background-color: ${theme.webMktPalette.background.dark};
        .ContentColumns__Item-Heading,.ContentColumns__Item-SubHeading,.ContentColumns__Item-List li,p {
          color: ${theme.webMktPalette.text.primaryWhite};
        }
        .ContentColumns__Item-Body {
          a {
            color: #38c6f4;
          }
        }
      `
    }
    if (moduleTheme === 'Light') {
      themeStyles = `
        // Light Styles
        background-color: ${theme.webMktPalette.background.white};
        .ContentColumns__Item-Heading,.ContentColumns__Item-SubHeading,.ContentColumns__Item-List li,p {
          color: ${theme.webMktPalette.text.primaryBlack};
        }
      `
    }

    if (moduleTheme === 'Gray') {
      themeStyles = `
        // Gray Styles
        background-color: ${theme.webMktPalette.background.gray};
        .ContentColumns__Item-Heading,.ContentColumns__Item-SubHeading,.ContentColumns__Item-List li,p {
          color: ${theme.webMktPalette.text.primaryBlack};
        }
      `
    }

    if (topPadding === 'Yes') {
      topPaddingStyles = `
        padding-top: 108px;
      `
    } else {
      topPaddingStyles = `
        padding-top: 0;
      `
    }

    if (bottomPadding === 'Large') {
      bottomPaddingStyles = `
        padding-bottom: 56px;
      `
    } else {
      bottomPaddingStyles = `
        padding-bottom: 36px;
      `
    }

    if (textAlignment === 'center') {
      orangeLineStyles = `
        left: 50%;
        transform: translateX(-50%);
      `
      richTextStyles = `
        margin: 0 auto 24px auto;
        width: fit-content;
      `
    }

    if (textAlignment === 'right') {
      orangeLineStyles = `
        right: 0;
      `
      richTextStyles = `
        margin: 0 0 24px auto;
        width: fit-content;
      `
    }

    if (textAlignment === 'left' || textAlignment === 'justify') {
      orangeLineStyles = `
        left: 0;
      `
    }

    return `
      ${themeStyles}
      ${topPaddingStyles}
      ${bottomPaddingStyles}
      .ContentColumns__Grid-Item {
        text-align: ${textAlignment};
        .ContentColumns__Item {
          &:before {
            ${orangeDivider ? orangeLineStyles : 'content: none;'}
          }
        }
        .ContentColumns__Item-List {
          ${
            richTextStyles &&
            `
              ul, ol {
                ${richTextStyles}
              }
              `
          }

        }
      }
    `
  }}

  .ContentColumns__Grid-Item {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 64px;
    ${(p) => p.theme.breakpoints.down('sm')} {
      margin-bottom: 48px;
    }
  }

  .ContentColumns__Upper-Heading {
    text-align: center;
    margin-bottom: 50px;
    padding: 32px 0 0;
    ${(p) => p.theme.breakpoints.down('xs')} {
      font-size: 28px;
      letter-spacing: 0px;
      line-height: 36px;
    }
  }
  .ContentColumns__Item {
    position: relative;
    ${(p) => p.theme.breakpoints.down('sm')} {
      margin-bottom: 24px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      max-width: 100%;
      margin-bottom: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 80px;
      height: 4px;
      background: ${(p) => p.theme.webMktPalette.orange[50]};
    }
  }
  .ContentColumns__Item-Heading {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -2px;
    line-height: 32px;
    margin: 0;
    ${(p) =>
      p.orangeDivider
        ? `
        padding-top: 28px;
        ${p.theme.breakpoints.down('sm')} {
          padding-top: 32px;
        }
        ${p.theme.breakpoints.down('xs')} {
          padding-top: 28px;
        }
      `
        : ''}
    ${(p) => p.theme.breakpoints.down('xs')} {
      letter-spacing: 0;
    }
  }
  .ContentColumns__Item-SubHeading {
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    margin-top: 24px;
    line-height: 24px;
  }
  .CTA {
    margin-top: 24px;
  }
  .ContentColumns__Item-List {
    margin-top: 24px;

    > *:last-child {
      margin-bottom: 0;
    }

    p,
    ul,
    ol {
      margin-bottom: 24px;
    }

    ul {
      list-style: disc;
      padding: 0 0 0 24px;
      li {
        padding-left: 8px;
        &:not(:first-of-type) {
          margin: 8px 0 0 0;
        }
      }
    }
    ol {
      padding: 0 0 0 24px;
      margin: 24px 0 0 0;
      li {
        padding-left: 8px;
        &:not(:first-of-type) {
          margin: 8px 0 0 0;
        }
      }
    }
    li,
    p {
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0px;
      line-height: 24px;
    }
    li {
      p {
        margin: 0;
      }
    }
  }
`
