import styled from 'styled-components'

export const MainCTA = styled.div`
  width: 100%;
  background: url(${(props) => props.backgroundDesktop}) center / cover
    no-repeat;
  padding: 96px 0;
  position: relative;
  z-index: 0;
  ${(p) => p.theme.breakpoints.down('sm')} {
    padding: 64px 0;
    background-image: url(${(props) => props.backgroundMobile});
  }

  .MainCTA__Inner {
    max-width: 792px;
    margin: 0 auto;
    text-align: center;
    ${(p) => p.theme.breakpoints.down('xs')} {
      width: 100%;
      box-sizing: border-box;
    }
  }
  .MainCTA__Title {
    color: rgb(0, 0, 0);
    font-size: 44px;
    font-weight: 600;
    text-align: center;
    letter-spacing: -2px;
    line-height: 48px;
    margin-bottom: 24px;

    ${(p) => p.theme.breakpoints.down('md')} {
      font-size: 40px;
      line-height: 44px;
    }

    ${(p) => p.theme.breakpoints.down('xs')} {
      margin-bottom: 16px;
      font-size: 28px;
      line-height: 36px;
    }
  }
  .MainCTA__Subtitle {
    color: rgb(0, 0, 0);
    font-size: 20px;
    margin-bottom: 32px;
    font-weight: normal;
    letter-spacing: -0.5px;
    line-height: 32px;
    ${(p) => p.theme.breakpoints.down('md')} {
      font-size: 18px;
      line-height: 28px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      letter-spacing: 0;
    }
  }
  .MainCTA__Disclaimer {
    color: rgba(0, 0, 0, 0.3);
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;
    margin-top: 32px;
  }
  .MainCTA__Buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: auto;
    align-items: center;

    .CTA:nth-child(2) {
      margin-left: 24px;
    }

    ${(p) => p.theme.breakpoints.down('xs')} {
      flex-direction: column;
      align-content: center;
      align-items: flex-start;

      .CTA {
        .CTA__Arrow-Container {
          padding-left: 16px;
          margin-left: auto;
        }
        width: 100%;
        box-sizing: border-box;
        &:first-child {
          margin-bottom: 24px;
        }
        &:nth-child(2) {
          margin-left: 0;
        }
      }
    }
  }
`
MainCTA.defaultProps = {
  backgroundDesktop: '',
  backgroundMobile: '',
}
