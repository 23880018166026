// import { determineActiveHost, hostIsLocal } from './environment.utils'

class ImgUtil {
  static getResizedImageUrl = (originalUrl: string, params: string) => {
    /*
      There is an issue with gifs not animating when run
      through the cloudflare image optimizer for chrome
    */
    if (
      originalUrl &&
      (originalUrl.endsWith('.svg') || originalUrl.endsWith('.gif'))
    ) {
      return originalUrl
    } else {
      return `https://uipath.com/cdn-cgi/image/${
        params ? params + ',' : ''
      }format=auto/${ImgUtil.imageUrlWithValidProtocol(originalUrl)}`
    }
  }

  // static regexResizedImageReplace = (
  //   regexCharacter: string,
  //   params: string
  // ) => {
  //   return `https://uipath.com/cdn-cgi/image/${
  //     params ? params + ',' : ''
  //   }format=auto/${regexCharacter}`
  // }

  // static imageUrlFromLocal = (localImageUrl: string, params: string = '') => {
  //   //determine if on localhost - or which prod env
  //   console.log(process.env)
  //   if (localImageUrl) {
  //     if (localImageUrl.startsWith('/')) {
  //       let envSpecificUrlHost = determineActiveHost()
  //       localImageUrl = `${envSpecificUrlHost}${localImageUrl}`
  //       if (hostIsLocal(envSpecificUrlHost)) {
  //         return localImageUrl
  //       }
  //     }

  //     return `https://uipath.com/cdn-cgi/image/${
  //       params ? params + ',' : ''
  //     }format=auto/${ImgUtil.imageUrlWithValidProtocol(localImageUrl)}`
  //   }
  //   return ''
  // }

  //   srcset="
  //   /cdn-cgi/image/fit=contain,width=320/assets/hero.jpg 320w,
  //   /cdn-cgi/image/fit=contain,width=640/assets/hero.jpg 640w,
  //   /cdn-cgi/image/fit=contain,width=960/assets/hero.jpg 960w,
  //   /cdn-cgi/image/fit=contain,width=1280/assets/hero.jpg 1280w,
  //   /cdn-cgi/image/fit=contain,width=2560/assets/hero.jpg 2560w,
  // "
  //sizes="50vw"> this should be the approx size of the image relative to the screen
  static imageUrlWithValidProtocol = (originalUrl: string) => {
    let imageUrl = originalUrl || ''
    if (!imageUrl.includes('http')) {
      imageUrl = `https://${imageUrl}`
    }
    return imageUrl
  }

  static getSrcSet = (originalUrl: string) => {
    let sizes = ['320', '640', '960', '1280', '2560']
    let imgArr = sizes.map((size) => {
      return `https://uipath.com/cdn-cgi/image/${
        'fit=contain,width=' + size + ','
      }format=auto/${ImgUtil.imageUrlWithValidProtocol(originalUrl)} ${size}w`
    })
    let returnString = imgArr.join(',')
    return returnString
  }
}

export default ImgUtil
