import styled from 'styled-components'

export const ResourceTabsContainer = styled.div`
  padding: 96px 0;
  background: #111212;
  position: relative;

  ${(p) => p.theme.breakpoints.down('xs')} {
    padding-bottom: 55px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 160px;
    transform: translateX(-50%);
    border-bottom: 4px solid #fa4616;
  }

  h2,
  h3,
  h6,
  li,
  p {
    color: #fff;
  }

  p,
  h3,
  h6 {
    margin: 0 0 16px;
  }

  h2 {
    margin-bottom: 48px;
    text-align: center;

    ${(p) => p.theme.breakpoints.down('sm')} {
      margin-bottom: 24px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      text-align: left;
    }
  }

  ul {
    padding: 0;
    list-style: none;
    display: flex;
    margin-bottom: 48px;
    justify-content: center;
    flex-wrap: wrap;

    li {
      cursor: pointer;
      padding: 8px 16px;
      border-radius: 32px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      background: #000;
      font-size: 14px;
      line-height: 24px;
      font-family: 'Inter', sans-serif;
      transition: all 0.2s;
      margin-bottom: 10px;

      &:not(:last-of-type) {
        margin-right: 16px;
      }

      &.activeTab,
      &:hover {
        background: #0067df;
        border-color: rgba(0, 0, 0, 0.3);
      }
    }

    ${(p) => p.theme.breakpoints.down('md')} {
      margin-bottom: 61px;
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
      margin-bottom: 38px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      margin-bottom: 14px;
      justify-content: flex-start;
    }
  }

  .ResourceTabs__Content-Container {
    display: none;
    opacity: 0;
    &.active {
      display: block;
      opacity: 1;
      animation: ResourceTabs__Animate 0.2s ease-in-out forwards;
    }
  }

  @keyframes ResourceTabs__Animate {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .ResourceTabs__Image-Container {
    padding-right: 126px;
    padding-bottom: 56px;
    position: relative;
    box-sizing: border-box;

    &:before {
      content: '';
      position: absolute;
      background-size: 26px 26px;
      background-image: radial-gradient(white 1px, transparent 0);
      bottom: 0;
      right: 12px;
      width: calc(100% - 126px);
      height: calc(100% - 56px);
    }

    img {
      object-fit: cover;
      width: 564px;
      height: 282px;
      position: relative;
    }

    ${(p) => p.theme.breakpoints.down('md')} {
      padding-right: 91px;
      img {
        width: 462px;
        height: 190px;
      }
      &:before {
        width: calc(100% - 91px);
      }
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
      padding-right: 65px;
      padding-bottom: 48px;
      &:before {
        width: calc(100% - 65px);
        height: calc(100% - 48px);
      }
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
      padding: 0 12px 16px;
      img {
        width: 100%;
      }
      &:before {
        content: none;
      }
    }
  }

  .ResourceTabs__Robot-Container {
    ${(p) => p.theme.breakpoints.down('sm')} {
      display: none;
    }
  }

  .ResourceTabs__Content--Top,
  .ResourceTabs__Content--Bottom {
    margin: 0 -12px;
    > div:not(.ResourceTabs__Image-Container) {
      padding-top: 0;
      padding-bottom: 0;

      > p {
        color: rgba(255, 255, 255, 0.3);
      }

      ${(p) => p.theme.breakpoints.down('xs')} {
        &:not(:last-of-type) {
          padding-bottom: 48px;
        }
      }
    }
  }

  .ResourceTabs__Content--Top {
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 71px;

    ${(p) => p.theme.breakpoints.down('md')} {
      margin-bottom: 50px;
    }

    ${(p) => p.theme.breakpoints.down('sm')} {
      margin-bottom: 54px;
    }

    ${(p) => p.theme.breakpoints.down('xs')} {
      margin-bottom: 0;
      flex-wrap: wrap-reverse;
    }
  }
`
