// Libraries
import React from 'react'

// Components
import Button from '../button'
import GlobalContainer from '../globalContainer'
import Grid from '@material-ui/core/Grid'

// Styles
import { ContentColumnsContainer } from './contentColumns'

// Helpers
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import options from '../../../utils/richText.utils'
import formatInlineCode from '../../../utils/shortTextFormatting'
import { internalUrl, internalLinkHandler } from '../../../utils/link.utils' 

const ContentColumns = (props: any) => {
  if (props.data) {
    let column1Cta: { externalUrl: string; text: string; internalLink?: internalUrl} | undefined,
      column2Cta: { externalUrl: string; text: string; internalLink?: internalUrl} | undefined,
      column3Cta: { externalUrl: string; text: string; internalLink?: internalUrl} | undefined,
      column4Cta: { externalUrl: string; text: string; internalLink?: internalUrl} | undefined,
      column5Cta: { externalUrl: string; text: string; internalLink?: internalUrl} | undefined,
      column6Cta: { externalUrl: string; text: string; internalLink?: internalUrl} | undefined

    const column1Headline = props.data.column1Headline
      ? props.data.column1Headline
      : ''
    const column2Headline = props.data.column2Headline
      ? props.data.column2Headline
      : ''
    const column3Headline = props.data.column3Headline
      ? props.data.column3Headline
      : ''
    const column4Headline = props.data.column4Headline
      ? props.data.column4Headline
      : ''

    const column5Headline = props.data.column5Headline
      ? props.data.column5Headline
      : ''

    const column6Headline = props.data.column6Headline
      ? props.data.column6Headline
      : ''

    const column1Body = props.data.column1Body ? props.data.column1Body : ''
    const column2Body = props.data.column2Body ? props.data.column2Body : ''
    const column3Body = props.data.column3Body ? props.data.column3Body : ''
    const column4Body = props.data.column4Body ? props.data.column4Body : ''
    const column5Body = props.data.column5Body ? props.data.column5Body : ''
    const column6Body = props.data.column6Body ? props.data.column6Body : ''

    const column1CtaText =
      props.data.column1Cta && props.data.column1Cta.text
        ? props.data.column1Cta.text
        : ''
    const column2CtaText =
      props.data.column2Cta && props.data.column2Cta.text
        ? props.data.column2Cta.text
        : ''
    const column3CtaText =
      props.data.column3Cta && props.data.column3Cta.text
        ? props.data.column3Cta.text
        : ''
    const column4CtaText =
      props.data.column4Cta && props.data.column4Cta.text
        ? props.data.column4Cta.text
        : ''
    const column5CtaText =
      props.data.column5Cta && props.data.column5Cta.text
        ? props.data.column5Cta.text
        : ''
    const column6CtaText =
      props.data.column6Cta && props.data.column6Cta.text
        ? props.data.column6Cta.text
        : ''
        
    if (props.data.column1Cta) column1Cta = props.data.column1Cta
    if (props.data.column2Cta) column2Cta = props.data.column2Cta
    if (props.data.column3Cta) column3Cta = props.data.column3Cta
    if (props.data.column4Cta) column4Cta = props.data.column4Cta
    if (props.data.column5Cta) column5Cta = props.data.column5Cta
    if (props.data.column6Cta) column6Cta = props.data.column6Cta
    
    const moduleSubtitle =
      props.data.subtitle && props.data.subtitle.json
        ? props.data.subtitle.json
        : ''
    const moduleTitle = props.data.title ? props.data.title : ''
    const theme = props.data.theme ? props.data.theme : 'Light'
    const bottomPadding = props.data.bottomPadding
      ? props.data.bottomPadding
      : 'Large'
    const topPadding = props.data.topPadding ? props.data.topPadding : 'No'
    const alignment = props.data.alignment ? props.data.alignment : 'left'
    const orangeDivider =
      props.data.orangeDivider !== null ? props.data.orangeDivider : true

    const isCol6 = column6Headline ? true : false
    const isCol5 = column5Headline ? true : false
    const isCol4 = column4Headline ? true : false
    const isCol3 = column3Headline ? true : false
    const isCol2 = column2Headline ? true : false
    const isCol1 = column1Headline ? true : false

    const getColCount = () => {
      if (isCol6) {
        return 6
      } else if (isCol5) {
        return 5
      } else if (isCol4) {
        return 4
      } else if (isCol3) {
        return 3
      } else {
        return 2
      }
    }

    let colsCount = getColCount()
    const generateContentItem = (
      heading: string,
      body: any,
      ctaText: string,
      cta: { externalUrl: string; text: string; internalLink?: internalUrl} | undefined
    ) => {
      let itemInner = (
        <div className={`ContentColumns__Item`}>
          {heading && (
            <h3 className="ContentColumns__Item-Heading">
              {formatInlineCode(heading)}
            </h3>
          )}
          {body && (
            <div className="ContentColumns__Item-Body ContentColumns__Item-List">
              {renderRichText(body, options)}
            </div>
          )}
          {ctaText && cta && (
            <Button
              icon={true}
              href={
                cta.internalLink
                  ? internalLinkHandler(cta.internalLink)
                  : cta.externalUrl
              }
              text={ctaText}
              type="text-link"
              size="small"
              theme={theme.toLowerCase()}
            />
          )}
        </div>
      )
      if (colsCount === 6 || colsCount === 5 || colsCount === 3) {
        return (
          <Grid
            className="ContentColumns__Grid-Item"
            item
            xs={12}
            sm={6}
            md={4}
          >
            {itemInner}
          </Grid>
        )
      }

      if (colsCount === 4) {
        return (
          <Grid
            className="ContentColumns__Grid-Item"
            item
            xs={12}
            sm={6}
            md={3}
          >
            {itemInner}
          </Grid>
        )
      }

      if (colsCount === 2 || colsCount === 1) {
        return (
          <Grid className="ContentColumns__Grid-Item" item xs={12} sm={6}>
            {itemInner}
          </Grid>
        )
      }
    }

    return (
      <ContentColumnsContainer
        moduleTheme={theme}
        bottomPadding={bottomPadding}
        topPadding={topPadding}
        textAlignment={alignment}
        orangeDivider={orangeDivider}
        data-cy="ContentColumns"
      >
        <GlobalContainer>
          {moduleTitle && (
            <h4 className="ContentColumns__Upper-Title">{moduleTitle}</h4>
          )}
          {moduleSubtitle && (
            <h5 className="ContentColumns__Upper-Subtitle">
              {renderRichText(moduleSubtitle, options)}
            </h5>
          )}
          <Grid container spacing={3}>
            {isCol1 &&
              generateContentItem(
                column1Headline,
                column1Body,
                column1CtaText,
                column1Cta ? column1Cta : undefined
              )}
            {isCol2 &&
              generateContentItem(
                column2Headline,
                column2Body,
                column2CtaText,
                column2Cta ? column2Cta : undefined
              )}
            {isCol3 &&
              generateContentItem(
                column3Headline,
                column3Body,
                column3CtaText,
                column3Cta ? column3Cta : undefined
              )}
            {isCol4 &&
              generateContentItem(
                column4Headline,
                column4Body,
                column4CtaText,
                column4Cta ? column4Cta : undefined
              )}
            {isCol5 &&
              generateContentItem(
                column5Headline,
                column5Body,
                column5CtaText,
                column5Cta ? column5Cta : undefined
              )}
            {isCol6 &&
              generateContentItem(
                column6Headline,
                column6Body,
                column6CtaText,
                column6Cta ? column6Cta : undefined
              )}
          </Grid>
        </GlobalContainer>
      </ContentColumnsContainer>
    )
  }

  return null
}

export default ContentColumns
